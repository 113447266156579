import toIndex from '../../Alphapet/toIndex'

export default function preparePaginationForQueryString(
  formData,
  count,
  resetPagination
) {
  let offset = 0

  if (resetPagination) return { offset }

  const pageNumber = toIndex(formData.get('pageNumber'), 0)

  offset = pageNumber * count

  return { offset }
}
