import React from 'react'
import classNames from 'classnames'

import { Button, Icon, InlineButton } from '../../..'
import { useTranslation } from '../../../../utils/providers'

import MultiSelectFilter from './MultiSelectFilter'

const filterComponents = {
  // We officially only support the list_multiselect filter. Others kept for future reference
  list_multiselect: MultiSelectFilter,
  //list_multiselect_custom_1: MultiSelectFilter,
  //range_slider: RangeFilter,
}

export default function MobileFilterList({
  isVisible = false,
  title,
  type,
  submitForms,
  closeFilter,
  ...rest
}) {
  const { t } = useTranslation()

  const Component = filterComponents[type]

  if (!Component) return null

  const handleSave = () => {
    submitForms().then(closeFilter)
  }

  const classes = classNames('mobile-filter__list', {
    'mobile-filter__list--visible': isVisible,
  })

  return (
    <div className={classes}>
      <div className="mobile-filter__list-header">
        <InlineButton className="px-2 py-0 mr-2" onClick={closeFilter}>
          <Icon symbol="cf-prev" />
        </InlineButton>
        <span>{title}</span>
      </div>
      <Component {...rest} />
      <div className="mobile-filter__list-footer text-center p-3">
        <Button variant="primary" onClick={handleSave}>
          {t('MOBILE_FILTER_SAVE')}
        </Button>
      </div>
    </div>
  )
}
