import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '../../..'
import { useTranslation } from '../../../../utils/providers'

export default function FilterResetButton({ resetAllFilters }) {
  const { t } = useTranslation()

  return (
    <Button
      variant="secondary"
      size="sm"
      className="product-list__filter-reset-button ml-auto"
      onClick={resetAllFilters}
    >
      {t('FILTER_RESET_ALL')}
    </Button>
  )
}

FilterResetButton.propTypes = {
  resetAllFilters: PropTypes.func.isRequired,
}
