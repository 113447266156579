export default function getAggregationToRender(aggregations = {}) {
  const aggregationsToRender = Object.entries(aggregations)
    .filter(filterRangeSlider)
    .filter(canShowFilter)
    .reduce(format, {})
  return aggregationsToRender
}

/**
 * @param input - Array that looks like - [KeyOfAggregation, {aggregation}]
 * @returns {boolean}
 */
function canShowFilter(input) {
  const [, aggregation] = input
  const { selectedValues, values } = aggregation || {}
  const aggrValues = values ? Object.keys(values) : []
  if (aggrValues.length == 1) {
    // Initially 2 filters were available both applied and then apply another one which remove one of the already applied in that case we would keep that filter option.
    if (
      selectedValues &&
      Object.values(selectedValues).indexOf(aggrValues[0]) > -1
    ) {
      return true
    }
    return false
  }
  return true
}

/**
 * @param input - Array that looks like - [KeyOfAggregation, {aggregation}]
 * @returns {boolean}
 */
function filterRangeSlider(input) {
  const [, aggregation] = input
  // First check if aggregation exists
  if (!aggregation) return true

  const { type = '', min, max } = aggregation

  if (type.includes('range_slider')) {
    if (min === max) {
      return false
    }
  }
  return true
}

function format(acc, current) {
  const [key, aggregation] = current

  acc[key] = aggregation

  return acc
}
