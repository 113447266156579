export default function collectPaginationFormData() {
  const paginationForm = document.querySelector('.list-pager')

  // Defensive Coding
  if (!paginationForm) {
    return new FormData()
  }

  return new FormData(paginationForm)
}
