import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { trackProductListFilterChange } from '../../../../utils/Alphapet/tracking'

export default function MultiSelectFilter({
  id,
  filterValues,
  selectedValues,
  categoryUrls,
  searchData,
}) {
  return (
    <ul>
      {filterValues.map((filter) => (
        <FilterEntry
          key={filter.key}
          id={id}
          filterValue={filter.key}
          selectedValues={selectedValues}
          categoryUrls={categoryUrls}
          searchData={searchData}
        />
      ))}
    </ul>
  )
}

function FilterEntry({
  id,
  filterValue,
  selectedValues = [],
  categoryUrls,
  searchData,
}) {
  const [isActive, setActive] = useState(false)

  function updateActiveState() {
    let updated = false
    if (Array.isArray(selectedValues)) {
      updated = selectedValues
        .map((val) => val.toLowerCase())
        .includes(filterValue.toLowerCase())
    }

    setActive(updated)
  }

  useEffect(updateActiveState, [selectedValues, filterValue])

  const classes = classNames('form-group mobile-filter__item', {
    ['active']: isActive,
  })

  const handleOnChange = (e) => {
    if (e?.target) {
      trackProductListFilterChange(
        e.target.value,
        categoryUrls,
        searchData,
        e.target.checked
      )
    }
    setActive(!isActive)
  }

  return (
    <li className={classes}>
      <label>
        <span>{filterValue}</span>
        <input
          type="checkbox"
          name={id}
          checked={isActive}
          value={filterValue}
          onChange={handleOnChange}
        />
      </label>
    </li>
  )
}
