import React from 'react'
import PropTypes from 'prop-types'

import { MediaResize, getAggregationToRender } from '../../../utils'

import FilterFlyout from './MobileFilter'
import FilterSidebar from './DesktopFilter'

function ProductListFilter({ aggregations = {}, ...rest }) {
  const aggregationsToRender = getAggregationToRender(aggregations)

  return (
    <>
      <MediaResize up="MD">
        <FilterSidebar aggregations={aggregationsToRender} {...rest} />
      </MediaResize>
      <MediaResize down="MD">
        <FilterFlyout aggregations={aggregationsToRender} {...rest} />
      </MediaResize>
    </>
  )
}

ProductListFilter.propTypes = {
  aggregations: PropTypes.object,
}

export default ProductListFilter
