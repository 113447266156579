import React from 'react'
import classNames from 'classnames'

import { trackProductListFilterChange } from '../../../../utils/Alphapet/tracking'

export default function MultiSelectFilter({
  id,
  filterValues,
  selectedValues,
  submitForms,
  categoryUrls,
  searchData,
}) {
  const handleOnChange = (e) => {
    submitForms()
    trackProductListFilterChange(
      e.target.value,
      categoryUrls,
      searchData,
      e.target.checked
    )
  }

  return (
    <ul className="desktop-filter__multi-select mt-3">
      {filterValues.map((filter) => {
        const { key: filterValue } = filter

        let isActive = false

        if (Array.isArray(selectedValues)) {
          // lowercase values for normalization purposes
          isActive = selectedValues
            .map((val) => val.toLowerCase())
            .includes(filterValue.toLowerCase())
        }

        const classes = classNames(
          'form-group desktop-filter__multi-select-item--default',
          {
            ['active']: isActive,
          }
        )

        return (
          <li key={filterValue} className={classes}>
            <label className="d-flex" tabIndex={-1}>
              <span>{filterValue}</span>
              <input
                type="checkbox"
                name={id}
                checked={isActive}
                value={filterValue}
                onChange={handleOnChange}
              />
            </label>
          </li>
        )
      })}
    </ul>
  )
}
